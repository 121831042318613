import { FunctionalComponent, h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";

const Header: FunctionalComponent = () => {
  return (
    <header class={style.header}>
      <Link href="/" class={style.headerLink}>
        Blaenavon Weather Station
      </Link>
    </header>
  );
};

export default Header;
